// 
// 
// user.scss
//
// Place your own custom CSS or SCSS rules below this line, these rules will override any Bootstrap and theme variables.

.hover-arrow {
    &:after {
        content: '»';
    }
}

.display-5 {
    font-size: 2em;
}
nav.navbar {
	button.dropdown-toggle-languages {
		background-color:transparent;
		margin-top: 3px;
		span {
			position: relative;
			i {
				font-size: 9px!important;
				position: absolute;
				right: -15px;
				top: 25%;
			}
		}
	}
}
section.instagram-fetch-block {
	.card.position-relative.text-light {
		background-color: transparent;
	}
}
svg.xs-mobile-icon {
	width: 90%;
}
.overlay {
	&:after {
	background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, darken($primary-2,10%) 150%);
	}
}
@media screen and (max-width: 576px) {
	.navbar-brand.fade-page {
		margin-right: 0px!important;
	}
}


@media screen and (max-width: 768px) {
     body.cookies div.table-container {
       	display: block!important;
       	overflow-x: auto!important;
     }
}
body.cookies table th,td {
	padding: 10px;
	border: 1px solid;
	display: none;
}

@media screen and (max-width: 768px) {
    .navbar.navbar-expand-lg.navbar-dark {
    	.navbar-brand.fade-page {
    		width: 80%;
    	}
    	.navbar-collapse.justify-content-end.collapse.show {
    		.text-uppercase.btn.dropdown-toggle.dropdown-toggle-languages.btn-ligh.btn-sm {
    			padding-left: 0px;
    		}
    	}
    }
}


